<script setup lang="ts">
import { useTRPC } from "~/plugins/trpcClient";
const $client = useTRPC();
const userSession = useUserSession();
const router = useRouter();

class LoginView {
  phone: string = "";
  password: string = "";

  $loading = false;

  async login() {
    this.$loading = true;

    try {
      const result = await $client.auth.login.mutate({
        phone: this.phone,
        password: this.password,
      });

      await userSession.fetch();

      await router.push(result.redirect);
    } catch (error) {
      console.log(error);
    }

    this.$loading = false;
  }
}

const view = reactive(new LoginView());
</script>
<template>
  <form
    @submit.prevent="view.login()"
    class="me-auto ms-auto mt-4 w-full max-w-md"
  >
    <div class="text-center">
      <h2 class="font-heading text-3xl font-medium leading-normal">
        Welcome back!
      </h2>
      <p
        class="font-alt text-sm font-normal leading-normal text-muted-400 mb-6"
      >
        Login with social media or your credentials
      </p>
    </div>
    <div class="px-8 py-4">
      <div class="mb-4 space-y-4">
        <InputField
          v-model="view.phone"
          label="Phone"
          :mask="['## #####-####']"
          :input-props="{
            type: 'text',
            name: 'phone',
            placeholder: '00 00000-0000',
          }"
        />

        <InputField
          v-model="view.password"
          label="Password"
          :input-props="{
            type: 'password',
            name: 'password',
            placeholder: '********',
          }"
        />
      </div>
      <div class="mb-6">
        <UButton
          type="submit"
          :loading="view.$loading"
          label="Sign In"
          color="primary"
          block
          size="lg"
        />
        <p
          class="text-muted-400 mt-4 flex justify-between font-sans text-sm leading-5"
        >
          <span>Don't have an account?</span>
          <WLink to="/register">Sign Up</WLink>
        </p>
      </div>

      <div class="mb-6 grid gap-0 sm:grid-cols-3">
        <hr
          class="border-muted-200 dark:border-muted-700 mt-3 hidden border-t sm:block"
        />
        <span
          class="bg-muted-100 dark:bg-muted-900 text-muted-400 relative top-0.5 text-center font-sans text-sm"
        >
          Or continue with
        </span>
        <hr
          class="border-muted-200 dark:border-muted-700 mt-3 hidden border-t sm:block"
        />
      </div>

      <div class="grid gap-2">
        <UButton
          to="/api/auth/github"
          icon="i-simple-icons-github"
          label="Login with GitHub"
          color="black"
          external
        />
      </div>
    </div>
  </form>
</template>
